//import {useEffect} from 'react';
import Head from 'next/head';
import dynamic from "next/dynamic";
import HomeBanner from "components/banners/HomeBanner";
import Counters from "components/common/Counters";
import ChooseSpoclearn from "components/common/ChooseSpoclearn";
import PopularCategories from "components/common/PopularCategories";
import Seo from "components/Seo";
// import OurBlogs from "components/blog/OurBlogs";

import KeyDifferentiators from "components/common/KeyDifferentiators";
import Banner from "components/common/Banner";
import PopularCourseSlider from "components/sliders/PopularCourseSlider";
import TrendingCourses from "components/common/TrendingCourses";
import GlobalOrganizationSlider from "components/sliders/GlobalOrganizationSlider";
import TrainingDeliveryModeSlider from "components/sliders/TrainingDeliveryModeSlider";
import CorporateLearningSolutionSlider from "components/sliders/CorporateLearningSolutionSlider";
import OurAccreditationsSlider from "components/sliders/OurAccreditationsSlider";
//#########################

import BackDropLoader from "components/BackDropLoader";
import Alert from "components/Alert";
import { initializeApollo, addApolloState } from "src/utils/apolloClient";
import { useQuery } from "@apollo/client";
import HOME_PAGE_QUERY from "src/graphql/query/homePageQuery";

import LoadComponent from "src/utils/loadComponents";
import mergeContents from "src/utils/mergeContents";
import AlternateLnks from "components/common/AlternateLnks";
const OurBlogs = dynamic(() => import("components/blog/OurBlogs"), {
  ssr: false,
});

/*import PopupQueryForm from "components/forms/PopupQueryForm";
import useFormDialog from "components/hooks/useFormDialog";
import FormDialog from "components/custom/FormDialog";
*/

//#############################
const Components = {
  homebanner: HomeBanner,
  counters: Counters,
  choosespoclearn: ChooseSpoclearn,
  ouraccreditationsslider: OurAccreditationsSlider,
  ourblogs: OurBlogs,
  popularcategories: PopularCategories,
  keydifferentiators: KeyDifferentiators,
  banner: Banner,
  popularcourseslider: PopularCourseSlider,
  trendingcourses: TrendingCourses,
  globalorganizationslider: GlobalOrganizationSlider,
  trainingdeliverymodeslider: TrainingDeliveryModeSlider,
  corporatelearningsolutionslider: CorporateLearningSolutionSlider,
};

const Home = () => {
  const { data, error, loading } = useQuery(HOME_PAGE_QUERY, {
    variables: {
      pageType: "HOME",
      slug: "//",
      countryCode: "us",
      popularCourseType: "GENERIC COURSE",
      popularCategoryType: "GENERIC CATEGORY",
    },
  });
  if (error)
    return (
      <Alert open={Boolean(error)} type="error">
        {error.message}
      </Alert>
    );
  if (loading && !data) return <BackDropLoader open={loading && !data} />;

  const { title, meta_description, meta_keyword, pageContents } =
    data?.page_by_type_slug;
  const { countries } = data;

  const { popularCourses, popularCategories } = data;
  const needToCombined = {
    PopularCategories: {
      content: {
        heading: "Discover our Popular Categories",
        categories: popularCategories,
      },
    },
    PopularCourseSlider: {
      content: {
        heading: "Discover our Popular Courses",
        courses: popularCourses,
      },
    },
  };
  const mergedData = mergeContents(pageContents, needToCombined);

  /*const [dropQuery, toggleDropQuery] = useFormDialog();

  const closePopUp = () => {
   
    localStorage.setItem("seenPopUp", false);
    toggleDropQuery(false);
  };

  useEffect(()=>{
    setTimeout(()=>{
      let returningUser = localStorage.getItem("seenPopUp");
      if(returningUser == false || returningUser == null){
        toggleDropQuery(true);
      }
    }, 4000)
  }, [])*/

  return (
    <>
      <Seo
        title={title}
        description={meta_description}
        keywords={meta_keyword}
      />
      <Head>        
        <script src="https://cdn.popupsmart.com/bundle.js" data-id="784199" async defer></script>
      </Head>

    {/*  <FormDialog
        open={dropQuery}
        onClose={closePopUp}
        title="Drop a query"
      >
        <PopupQueryForm afterSubmit={closePopUp} />
      </FormDialog>*/}
      <AlternateLnks countries={countries} />
      {mergedData?.map((item) => LoadComponent(item, Components))}
    </>
  );
};
export default Home;

export async function getStaticProps() {
  const apolloClient = initializeApollo();
  await apolloClient.query({
    query: HOME_PAGE_QUERY,
    variables: {
      pageType: "HOME",
      slug: "//",
      countryCode: "us",
      popularCourseType: "GENERIC COURSE",
      popularCategoryType: "GENERIC CATEGORY",
    },
  });
  return addApolloState(apolloClient, {
    props: {},
    revalidate: 1,
  });
}
